import Insights from "@components/Insights";
import Properties from "@components/Properties";

function Overview() {
  return (
    <>
      <div className="flex w-full overflow-hidden mr-8">
        <Insights />
        <Properties />
      </div>
    </>
  );
}

export default Overview;
