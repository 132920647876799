import { Checkbox, Typography } from "@material-tailwind/react";
import {
  GranularityType,
  GraphType,
  MetricFilter,
  MetricGraph,
} from "../../models/models";
import { PrimaryButton } from "../Buttons";
import { DropdownFilter } from "./DropdownFilter";
import { useCallback, useEffect, useState } from "react";
import { generateTrendGragh, getProperties } from "../../api/api";
import { RangePicker } from "../RangePicker";
import { capitalizeAll, getYearAgoDate } from "../../utils/helpers";
import {
  addTrend,
  addTrendOnProccesing,
  removeTrendOnProccesing,
} from "../../features/trends/trends-slice";
import { useAppDispatch } from "../../app/hooks";
import { SmallText } from "../../styles/Typography";

type ComplementaryDataProps = {
  selectedMetric: MetricGraph;
  setLoadingAnswer: (value: boolean) => void;
};

export const ComplementaryData = ({
  selectedMetric,
  setLoadingAnswer,
}: ComplementaryDataProps) => {
  const [properties, setProperties] = useState<string[]>([]);
  const [selectedProperties, setSelectedProperties] = useState<string[]>([]);
  const [perProperty, setPerProperty] = useState<boolean>(false);
  const [selectedBreakdownPeriod, setSelectedBreakdownPeriod] =
    useState<GranularityType>("month");
  const [selectedGraphType, setSelectedGraphType] = useState<GraphType>("line");
  const [enableGenerate, setEnableGenerate] = useState(false);
  const [generatedSuccessfully, setGeneratedSuccessfully] = useState(false);
  const requiredInputs = selectedMetric?.optional_params;
  const [fromDate, setFromDate] = useState<Date>(new Date(getYearAgoDate()));
  const [toDate, setToDate] = useState<Date>(new Date());
  const [track, setTrack] = useState(false);
  const [error, setError] = useState<string | null>();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const fetchOptions = async () => {
      setProperties((await getProperties()).map((p) => p.name));
    };

    fetchOptions();
  }, []);

  useEffect(() => {
    setGeneratedSuccessfully(false);
    selectedMetric && setEnableGenerate(true);
    setError(null);
  }, [
    selectedMetric,
    selectedGraphType,
    selectedBreakdownPeriod,
    selectedProperties,
    perProperty,
    fromDate,
    toDate,
    track,
  ]);

  const PerProperty = useCallback(() => {
    if (
      selectedProperties?.length > 1 &&
      (selectedGraphType === "bar" || selectedGraphType === "line")
    ) {
      return (
        <DropdownFilter
          btnTitle={"Calculation method"}
          options={["Average", "Per property"]}
          onSelectCallback={(selected: string[]) => {
            setPerProperty(selected[0] === "Per property");
          }}
          multiSelect={false}
        />
      );
    }

    return <></>;
  }, [selectedProperties?.length, selectedGraphType]);

  const generateDashboard = async () => {
    try {
      dispatch(addTrendOnProccesing());
      // setLoadingAnswer(true);
      setEnableGenerate(false);
      const graphJson = await generateTrendGragh({
        graph_name: selectedMetric?.graph_name || "",
        chart_type: selectedGraphType as GraphType,
        properties: selectedProperties || [],
        per_property: perProperty,
        metrics: true,
        granularity: (selectedBreakdownPeriod as GranularityType) || null,
        date_range: [fromDate, toDate],
        track,
      });
      if (graphJson) {
        dispatch(addTrend(graphJson));
        setGeneratedSuccessfully(true);
        setError(null);
      } else {
        dispatch(removeTrendOnProccesing());
        setError("No data available for this time period.");
        setTimeout(() => {
          setError(null);
        }, 7000);
      }
      // setLoadingAnswer(false);
      setEnableGenerate(true);
    } catch (e) {
      dispatch(removeTrendOnProccesing());
      // setLoadingAnswer(false);
      setGeneratedSuccessfully(false);
      setEnableGenerate(false);
      setError(e as string);
      setTimeout(() => {
        setError(null);
      }, 7000);
    }
  };

  return (
    <>
      {requiredInputs?.indexOf(MetricFilter.GRANULARITY) !== undefined &&
      requiredInputs?.indexOf(MetricFilter.GRANULARITY) > -1 ? (
        <div className="my-10">
          <DropdownFilter
            btnTitle={"Breakdown Period"}
            options={["week", "month", "quarter", "year"]}
            onSelectCallback={(selected) => {
              setSelectedBreakdownPeriod(selected[0] as GranularityType);
              setEnableGenerate(true);
            }}
            multiSelect={false}
          />
        </div>
      ) : (
        ""
      )}

      {requiredInputs?.indexOf(MetricFilter.CHRT_TYPE) !== undefined &&
      requiredInputs?.indexOf(MetricFilter.CHRT_TYPE) > -1 ? (
        <div className="my-10">
          <DropdownFilter
            btnTitle={"Graph Type"}
            options={["Bar", "Line"]} //, "Pie", "Donut"
            onSelectCallback={(selected) => {
              setSelectedGraphType(selected[0]?.toLowerCase() as GraphType);
            }}
            multiSelect={false}
          />
        </div>
      ) : (
        ""
      )}

      {requiredInputs?.indexOf(MetricFilter.PROPERTIES) !== undefined &&
      requiredInputs?.indexOf(MetricFilter.PROPERTIES) > -1 ? (
        <DropdownFilter
          btnTitle={"Select Properties (max 5)"}
          options={properties}
          onSelectCallback={(selected: string[]) => {
            setSelectedProperties(selected);
          }}
          multiSelect={true}
          maxOptions={5}
        />
      ) : (
        ""
      )}
      <PerProperty />
      {requiredInputs?.indexOf(MetricFilter.DATE_RANGE) !== undefined &&
      requiredInputs?.indexOf(MetricFilter.DATE_RANGE) > -1 ? (
        <RangePicker
          setLiveTracking={setTrack}
          fromDateSetter={setFromDate}
          toDateSetter={setToDate}
        />
      ) : (
        ""
      )}
      <div>
        {enableGenerate ? (
          <div className={`pt-8 m-auto text-center`}>
            <PrimaryButton
              className={`!w-66 m-auto ${
                generatedSuccessfully && "opacity-50 pointer-events-none"
              }`}
              onClick={generateDashboard}
            >
              + Generate Dashboard
            </PrimaryButton>
          </div>
        ) : (
          ""
        )}
        {error ? (
          <Typography className="text-center text-red-500 bg-red-50/20 rounded-md p-2 py-3">
            {error}
          </Typography>
        ) : (
          generatedSuccessfully && (
            <Typography className="text-center text-green-300">
              Dashboard generated successfully!
            </Typography>
          )
        )}
      </div>
    </>
  );
};
