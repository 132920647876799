import {
  LargeText,
  ParagraphTitle,
  SmallText,
  Subtext,
} from "../styles/Typography";
import { PropertyData } from "../models/models";
import { LineMetric, TrendMetric } from "./LineMetric";
import {
  calculateDelinquencyRate,
  calculateDelinquencyTrend,
  capitalizeAll,
  displayValue,
} from "../utils/helpers";
import { Drilldown, DrilldownType, IconSize } from "./Drilldown";
import { UpdatedAtIcon } from "./Icons";

type MetricCardProps = {
  id: string;
  drilldownExportID?: string;
  drilldownExportTitle?: string;
  drilldownDate?: string;
  type?: DrilldownType;
  title: string | JSX.Element;
  value?: number | string | null;
  prefix?: string;
  suffix?: string;
  trend?: number | null;
  other?: JSX.Element;
  className?: string;
  drillDown?: any;
  toFixed?: number;
  smallText?: boolean;
};

const MetricCard = ({
  id,
  type,
  title,
  value,
  prefix,
  suffix,
  trend,
  other,
  className,
  drillDown,
  drilldownExportID,
  drilldownExportTitle,
  drilldownDate,
  toFixed,
  smallText,
}: MetricCardProps) => {
  return (
    <div
      className={`relative group ${
        className ?? ""
      } hover:bg-secondary-gray-350/10  ${
        drillDown ? "px-5 py-3" : "p-3"
      } mr-2`}
    >
      <div className={`relative flex gap-2 ${drillDown ? "pt-4" : ""}`}>
        <SmallText className="!mb-2">{title}</SmallText>
        {drillDown && (
          <Drilldown
            id={id}
            drilldownExportID={drilldownExportID}
            type={type}
            context={{}}
            size={IconSize.small}
            className="opacity-0 group-hover:opacity-100"
            drilldownExportTitle={drilldownExportTitle}
            drilldownDate={drilldownDate}
          />
        )}
      </div>
      <div className={`flex flex-col gap-2`}>
        {other ? (
          other
        ) : (
          <>
            {value != null ? (
              smallText ? (
                <SmallText className="!text-[11px] whitespace-nowrap !font-semibold">
                  {typeof value === "string"
                    ? value
                    : displayValue(value, prefix ?? "", suffix ?? "%", toFixed)}
                </SmallText>
              ) : (
                <LargeText className="font-normal">
                  {typeof value === "string"
                    ? value
                    : displayValue(value, prefix ?? "", suffix ?? "%", toFixed)}
                </LargeText>
              )
            ) : (
              "N/A"
            )}
            {trend !== null && trend !== undefined ? (
              <TrendMetric trend={trend} />
            ) : (
              ""
            )}
          </>
        )}
      </div>
    </div>
  );
};

type YearlyComparisonMetricProps = {
  title?: string;
  currentYear: number | null;
  lastYear: number | null;
};

const YearlyComparisonMetric = ({
  title,
  currentYear,
  lastYear,
}: YearlyComparisonMetricProps) => {
  const currentYearIsBetter =
    currentYear && lastYear ? currentYear > lastYear : true;
  const curYearPercentage =
    currentYear && lastYear
      ? currentYearIsBetter
        ? 100
        : (currentYear / lastYear) * 100
      : 100;
  const lastYearPercentage =
    currentYear && lastYear
      ? currentYearIsBetter
        ? (lastYear / currentYear) * 100
        : 100
      : 100;

  return (
    <>
      {title ? (
        <div className="mt-1">
          <Subtext>{title}</Subtext>
        </div>
      ) : (
        ""
      )}
      {currentYear ? (
        <div className="flex gap-1">
          <div
            style={{ width: `${curYearPercentage.toFixed(0)}%` }}
            className={`h-[3px] bg-primary my-2`}
          ></div>
          <SmallText className="text-primary !m-0 font-custom">
            {displayValue(currentYear, "$")}
          </SmallText>
        </div>
      ) : (
        <div className="h-5">
          -{" "}
          <span className="text-secondary-gray-350 text-[11px] font-extralight">
            [No data for this period]
          </span>
        </div>
      )}
      {lastYear ? (
        <div className="flex gap-1 !mb-4">
          <div
            style={{ width: `${lastYearPercentage.toFixed(0)}%` }}
            className={`h-[3px] bg-primary-soft my-2`}
          ></div>
          <SmallText className="text-primary-soft !m-0 font-custom">
            {displayValue(lastYear, "$")}
          </SmallText>
        </div>
      ) : (
        <div className="h-5 mb-4">
          -{" "}
          <span className="text-secondary-gray-350 text-[11px] font-extralight">
            [No data for this period]
          </span>
        </div>
      )}
    </>
  );
};

export function Property({
  name,
  unitsCount,
  occupancy,
  delinquency,
  maintenanceExpenses,
  budget,
  renewals,
  collection,
  utilitiesExpenses,
  lossToLease,
  // leaseRatio,
  expiredLeases,
  noi,
  date,
  lastUpdatedAt,
}: PropertyData) {
  const delinquencyPrc = calculateDelinquencyRate(
    delinquency?.actual,
    delinquency?.grossPotentialRent
  );

  const delinquencyTrend = calculateDelinquencyTrend(
    delinquency?.actual,
    delinquency?.grossPotentialRent,
    delinquency?.previousActual,
    delinquency?.previousGrossPotentialRent
  );

  const totalRenuals =
    (renewals?.confirmedRenewals || 0) +
    (renewals?.turnovers || 0) +
    (renewals?.nonConfirmedMoveouts || 0);

  return (
    <div className=" bg-gradient-to-r from-secondary-gray-350 to-secondary-gray-50 py-[1px] pl-0 mr-8 rounded-sm">
      <div
        className={`relative bg-white  border-l-8 border-l-secondary-gray-400 min-w-16 min-h-8 p-4 m-0 rounded-sm shadow-lg flex flex-col w-full gap-2`}
      >
        <div className="absolute top-[2px] left-0 ml-[-8px] h-[99%] w-2 bg-gradient-to-l from-secondary-gray-500 via-white to-secondary-gray-500 rounded-full shadow-[4.0px_2.0px_11.0px_rgba(0,0,0,0.38)]"></div>
        <div className="flex justify-between">
          <ParagraphTitle>
            {capitalizeAll(name || "")}{" "}
            {unitsCount ? (
              <span className="font-light">({unitsCount} units)</span>
            ) : (
              ""
            )}
          </ParagraphTitle>
          {lastUpdatedAt ? (
            <div className="flex gap-2 items-center pl-6 text-secondary-gray-400">
              <UpdatedAtIcon />
              <span className=" opacity-50">
                last updated at {new Date(lastUpdatedAt).toLocaleDateString()}
              </span>
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="inline-flex w-full border-separate-[1px] border-secondary-gray-100">
          <MetricCard
            title="NOI"
            id={noi?.drilldownID}
            drilldownExportID={noi?.drilldownExportID}
            drilldownDate={date || undefined}
            drilldownExportTitle="NOI"
            value={noi?.value}
            prefix="$"
            suffix=""
            trend={noi?.trend}
            drillDown={{}}
            className="w-1/4"
            toFixed={0}
          />
          {/* <MetricCard
            title="Lease Ratio"
            id={leaseRatio?.drilldownID}
            value={`${
              leaseRatio?.signedLeases == undefined
                ? "N/A"
                : leaseRatio?.signedLeases
            } / ${
              leaseRatio?.applicants == undefined
                ? "N/A"
                : leaseRatio?.applicants
            }`}
            // trend={leaseRatio?.trend}
            drillDown={{}}
            className="w-1/4 border-secondary-gray-100"
          /> */}
          <MetricCard
            title={
              <>
                Expired Leases{" "}
                <span className="pl-1 text-[10px]">
                  (
                  {(date ? new Date(date) : new Date()).toLocaleString(
                    "default",
                    {
                      month: "short",
                    }
                  )}
                  )
                </span>
              </>
            }
            id={expiredLeases?.drilldownID}
            drilldownExportID={expiredLeases?.drilldownExportID}
            drilldownExportTitle="Expired_Leases"
            drilldownDate={date || undefined}
            value={expiredLeases?.value}
            type={DrilldownType.EXPIRED_LEASES}
            suffix=""
            drillDown={{}}
            className="w-1/4 border-secondary-gray-100"
          />
          <MetricCard
            title="Repairs and Maintenance"
            type={DrilldownType.MAINTENANCE}
            id={maintenanceExpenses?.drilldownID}
            drilldownExportID={maintenanceExpenses?.drilldownExportID}
            drilldownDate={date || undefined}
            drilldownExportTitle="Repairs_and_Maintenance"
            value={maintenanceExpenses?.value}
            prefix="$"
            suffix=""
            trend={maintenanceExpenses?.trend}
            drillDown={{}}
            className="w-1/4"
            toFixed={0}
          />
          <MetricCard
            title="Loss to Lease"
            id={lossToLease?.drilldownID}
            drilldownExportID={lossToLease?.drilldownExportID}
            drilldownDate={date || undefined}
            drilldownExportTitle="Loss_to_Lease"
            type={DrilldownType.LOSS_TO_LEASE}
            other={
              <>
                {lossToLease?.charged == null ||
                lossToLease?.marketValue === null
                  ? "N/A"
                  : `${displayValue(
                      lossToLease?.marketValue - lossToLease?.charged,
                      "$",
                      "",
                      0
                    )}`}
                <LineMetric
                  metric1={{
                    title: "Rent",
                    value: lossToLease?.charged,
                    suffix: "",
                    prefix: "$",
                  }}
                  metric2={{
                    title: "Market Rent",
                    value: lossToLease?.marketValue,
                    suffix: "",
                    prefix: "$",
                  }}
                  small={true}
                  customRiskStatus={
                    !!(
                      lossToLease?.charged &&
                      lossToLease?.marketValue &&
                      (lossToLease?.charged / lossToLease?.marketValue) * 100 >
                        30
                    )
                  }
                />
              </>
            }
            value={
              lossToLease?.charged == null || lossToLease?.marketValue === null
                ? "N/A"
                : `${displayValue(
                    lossToLease?.marketValue - lossToLease?.charged,
                    "$",
                    "",
                    0
                  )} (${displayValue(
                    (lossToLease?.charged / lossToLease?.marketValue) * 100,
                    "",
                    "%",
                    0
                  )})`
            }
            trend={noi?.trend}
            suffix=""
            drillDown={{}}
            className="w-1/4"
            toFixed={0}
          />
        </div>
        <div className="flex gap-4 w-full">
          <div className="border-r-[1px] border-secondary-gray-100 w-1/2">
            <div>
              <MetricCard
                className="pr-5"
                id={occupancy.drilldownID}
                drilldownExportID={occupancy?.drilldownExportID}
                drilldownDate={date || undefined}
                drilldownExportTitle="Occupancy"
                type={DrilldownType.OCCUPANCY}
                title="Occupancy"
                drillDown={{}}
                other={
                  <LineMetric
                    metric1={{
                      title: "Value",
                      value: occupancy.value,
                      suffix: "%",
                    }}
                    trend={{
                      title: "Trend",
                      value: occupancy.trend,
                      suffix: "%",
                    }}
                  />
                }
              />
              <MetricCard
                className="pr-5"
                id={delinquency.drilldownID}
                drilldownExportID={delinquency?.drilldownExportID}
                drilldownDate={date || undefined}
                drilldownExportTitle="Delinquency"
                type={DrilldownType.DELINQUENCY}
                title="Delinquency (0-30 Days)"
                drillDown={{}}
                other={
                  <LineMetric
                    metric1={{
                      title: "Value",
                      value:
                        delinquencyPrc === undefined ? null : delinquencyPrc,
                      prefix:
                        delinquency?.previousGrossPotentialRent === -1
                          ? "$"
                          : "",
                      suffix:
                        delinquency?.previousGrossPotentialRent === -1
                          ? ""
                          : "%",
                    }}
                    trend={{
                      title: "Trend",
                      value: delinquencyTrend,
                      suffix: "%",
                    }}
                    reversePercentageLine={true}
                  />
                }
              />
              <MetricCard
                className="pr-5"
                id={budget.drilldownID}
                drilldownExportID={budget?.drilldownExportID}
                drilldownDate={date || undefined}
                drilldownExportTitle="Expenses"
                type={DrilldownType.BUDGET}
                title="Expenses"
                drillDown={{}}
                other={
                  <LineMetric
                    metric1={{
                      title: "Actual",
                      value: budget.actual,
                      prefix: "$",
                    }}
                    metric2={{
                      title: "Budget",
                      value: budget.planned,
                      prefix: "$",
                    }}
                  />
                }
              />
            </div>
          </div>
          <div className="w-1/2">
            <div>
              <MetricCard
                className="relative sm:pr-0 sm:mr-1 lg:pr-5 lg:mr-2"
                id={utilitiesExpenses.drilldownID}
                drilldownExportID={utilitiesExpenses?.drilldownExportID}
                drilldownDate={date || undefined}
                drilldownExportTitle="Utilities"
                type={DrilldownType.UTILITIES}
                title="Utilities (QoQ)"
                drillDown={{}}
                other={
                  <div className="flex flex-col justify-between w-full">
                    <YearlyComparisonMetric
                      currentYear={utilitiesExpenses.current}
                      lastYear={utilitiesExpenses.previous}
                    />
                    <div className=" flex flex-col gap-1">
                      <div className="flex gap-1 items-center">
                        <div className="rounded-full w-2 h-2 bg-primary" />
                        <Subtext className="!text-[11px] font-normal whitespace-nowrap !text-secondary-gray-500">
                          Current Quarter
                        </Subtext>
                      </div>
                      <div className="flex gap-1 items-center">
                        <div className="rounded-full w-2 h-2 bg-primary-soft" />
                        <Subtext className="!text-[11px] font-normal whitespace-nowrap !text-secondary-gray-500">
                          Same Quarter Last Year
                        </Subtext>
                      </div>
                    </div>
                  </div>
                }
              />
              <MetricCard
                className="sm:pr-0 sm:mr-1 lg:pr-5 lg:mr-2"
                id={renewals?.drilldownID}
                drilldownExportID={renewals?.drilldownExportID}
                drilldownDate={date || undefined}
                drilldownExportTitle="Renewals"
                type={DrilldownType.RENEWALS}
                title="Renewals"
                drillDown={{}}
                other={
                  renewals?.confirmedRenewals ||
                  renewals?.turnovers ||
                  renewals?.nonConfirmedMoveouts ? (
                    <>
                      <div className="flex gap-0 overflow-hidden w-full h-3 rounded-full">
                        {renewals?.confirmedRenewals ? (
                          <div
                            style={{
                              width: `${(totalRenuals > 0
                                ? (renewals?.confirmedRenewals / totalRenuals) *
                                  100
                                : 0
                              ).toFixed(0)}%`,
                            }}
                            className={`h-full bg-[#BFDAFE]`}
                          ></div>
                        ) : (
                          ""
                        )}
                        {renewals?.turnovers ? (
                          <div
                            style={{
                              width: `${(totalRenuals > 0
                                ? (renewals?.turnovers / totalRenuals) * 100
                                : 0
                              ).toFixed(0)}%`,
                            }}
                            className={`h-full bg-[#CAE5D2]`}
                          ></div>
                        ) : (
                          ""
                        )}
                        {renewals?.nonConfirmedMoveouts ? (
                          <div
                            style={{
                              width: `${(totalRenuals > 0
                                ? (renewals?.nonConfirmedMoveouts /
                                    totalRenuals) *
                                  100
                                : 0
                              ).toFixed(0)}%`,
                            }}
                            className={`h-full bg-[#FAEBC0]`}
                          ></div>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="w-full flex flex-col gap-1">
                        <div className="flex gap-2 items-center">
                          <div className="rounded-full w-2 h-2 bg-[#BFDAFE]"></div>
                          <Subtext className="whitespace-nowrap !text-[11px] font-normal !text-secondary-gray-500">
                            {(
                              ((renewals?.confirmedRenewals || 0) /
                                totalRenuals) *
                              100
                            ).toFixed(0)}
                            {"%  "}
                            Confirmed Renewals
                          </Subtext>
                        </div>
                        <div className="flex gap-2 items-center">
                          <div className="rounded-full w-2 h-2 bg-[#CAE5D2]"></div>
                          <Subtext className="whitespace-nowrap !text-[11px] font-normal !text-secondary-gray-500">
                            {(
                              ((renewals?.turnovers || 0) / totalRenuals) *
                              100
                            ).toFixed(0)}
                            {"%  "} Turnovers
                          </Subtext>
                        </div>
                        <div className="flex gap-2 items-center">
                          <div className="rounded-full w-2 h-2 bg-[#FAEBC0]"></div>
                          <Subtext className="whitespace-nowrap !text-[11px] font-normal !text-secondary-gray-500">
                            {(
                              ((renewals?.nonConfirmedMoveouts || 0) /
                                totalRenuals) *
                              100
                            ).toFixed(0)}
                            {"%  "} Non-confirmed Move-outs
                          </Subtext>
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className="h-5 mb-4">
                      -{" "}
                      <span className="text-secondary-gray-350 text-[11px] font-extralight">
                        [No data for this period]
                      </span>
                    </div>
                  )
                }
              />
              <MetricCard
                className="pr-5"
                id={collection?.drilldownID}
                drilldownExportID={collection?.drilldownExportID}
                drilldownDate={date || undefined}
                drilldownExportTitle="Collection"
                title="Collection"
                type={DrilldownType.COLLECTION}
                drillDown={{}}
                other={
                  <LineMetric
                    metric1={{
                      title: "Actual",
                      value: collection?.value,
                      prefix: "$",
                    }}
                    metric2={{
                      title: "Planned",
                      value: collection?.target,
                      prefix: "$",
                    }}
                    customRiskStatus={
                      collection?.value && collection?.target
                        ? collection?.value / collection?.target > 0.85
                        : true
                    }
                  />
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
