import { DrilldownType } from "@components/Drilldown";
import {  BudgetDrilldownDTO, CollectionDrilldownDTO, DelinquencyDrilldownDTO, DrilldownResponseType, MaintenanceDrilldownDTO, RenewalsDrilldownDTO, OccupancyDrilldownDTO, RenovationsDrilldownDTO, TrafficApplicantsDrilldownDTO, UtilitiesDrilldownDTO, LossToLeaseDrilldownDTO } from "../models/models";
import { displayValue } from "./helpers";

export const parseDrilldownText = (data: string[]) => {
    return data.map(line => {
        return line.replace(/<b>/g,'<span style="font-weight:900">')
        .replace(/<\/b>/g,'</span>')
        .replace(/<c-([a-zA-Z0-9#]+)>(.*?)<\/c-\1>/g, '<span style="color:$1">$2</span>')
        .replace(/<L>(.*?)<\/L>/g, '<div style="background-color: rgba(200,200,255,0.2); border-radius:10px;padding:20px;border:1px solid #bababa;opacity:0.9;">$1</div>')
        .replace('</b>','</span>')
        .replace(/### (.+?)<br\s*\/?>/g, (match, p1) => `<span style="text-decoration:underline">${p1}</span><br/>`);
    }).join('<br/>');
}

export const drilldownText = (type?: DrilldownType, data?: DrilldownResponseType) => {
    switch(type) {
        case DrilldownType.MAINTENANCE:
            return (data as MaintenanceDrilldownDTO).map((d)=> `<span style="font-weight:bold">${d.accountName} - </span> ${displayValue(d?.amount,'$')}`).join('<br/>');
        case DrilldownType.RENOVATIONS:
            return `Units that are planned to get renovated in the next 30 days:<br/> ${(data as RenovationsDrilldownDTO).map((d)=> `<span style="font-weight:bold">${d.unitNumber} </span>`).join('<br/>')}`;
        case DrilldownType.OCCUPANCY:
            let ans = '';
            for(let i=0;i<(data as OccupancyDrilldownDTO)?.length;){
                let type = (data as OccupancyDrilldownDTO)[i]?.unitType;
                ans += `<span style="font-weight:bold"><u>Unit type ${(data as OccupancyDrilldownDTO)[i]?.unitType}:</u></span><br/>
                    ${(data as OccupancyDrilldownDTO)[i]?.status} - ${(data as OccupancyDrilldownDTO)[i]?.number} (${(data as OccupancyDrilldownDTO)[i]?.percentage}%)<br/>`;
                i+=1;
                if((data as OccupancyDrilldownDTO)[i]?.unitType !== type) {
                    ans += '<br/>';
                    continue;
                }
                ans+=`${(data as OccupancyDrilldownDTO)[i]?.status} - ${(data as OccupancyDrilldownDTO)[i]?.number} (${(data as OccupancyDrilldownDTO)[i]?.percentage}%)<br/>`;
                i+=1;
                if((data as OccupancyDrilldownDTO)[i]?.unitType !== type) {
                    ans += '<br/>';
                    continue;
                }
                ans+=`${(data as OccupancyDrilldownDTO)[i]?.status} - ${(data as OccupancyDrilldownDTO)[i]?.number} (${(data as OccupancyDrilldownDTO)[i]?.percentage}%)<br/>`;
                i+=1;
                ans += '<br/>';
            };
            return ans;
            // return `Vacant units:<br/> ${(data as OccupancyDrilldownDTO).map((d)=> `<span style="font-weight:bold">${d.unitType} </span> units of unit type ${d.unitNumber ? `${d.unitNumber} are vacant`:''}`).join('<br/>')}`;
        case DrilldownType.UTILITIES:
            return `${(data as UtilitiesDrilldownDTO)?.electricity ? (data as UtilitiesDrilldownDTO)?.electricity.map((d)=>`<u>Electricity:<br/></u><span style="font-weight:bold">${d?.unitNumber || 'Some unit'} - </span> ${displayValue(d?.current,'$')} (Same Quarter Last Year - ${displayValue(d?.previous,'$')})<br/><br/>`):''}
                ${(data as UtilitiesDrilldownDTO)?.gas ? (data as UtilitiesDrilldownDTO)?.gas.map((d)=>`<u>Gas:<br/></u><span style="font-weight:bold">${d?.unitNumber || 'Some unit'} - </span> ${displayValue(d?.current,'$')} (Same Quarter Last Year - ${displayValue(d?.previous,'$')})<br/><br/>`):''}               
                ${(data as UtilitiesDrilldownDTO)?.water ? (data as UtilitiesDrilldownDTO)?.water?.map((d)=>`<u>Water:<br/></u><span style="font-weight:bold">${d?.unitNumber || 'Some unit'} - </span> ${displayValue(d?.current,'$')} (Same Quarter Last Year - ${displayValue(d?.previous,'$')})<br/><br/>`):''}
                ${(data as UtilitiesDrilldownDTO)?.other ? (data as UtilitiesDrilldownDTO)?.other?.map((d)=>`<u>Other:<br/></u><span style="font-weight:bold">${d?.unitNumber || 'Some unit'} - </span> ${displayValue(d?.current,'$')} (Same Quarter Last Year - ${displayValue(d?.previous,'$')})<br/>`):''}`;
        case DrilldownType.DELINQUENCY:
            return (data as DelinquencyDrilldownDTO).map((d)=> `<span style="font-weight:bold">${d.unitNumber} - </span> ${d.period} ${d.amount ? `(Uncollected rent: ${displayValue(d?.amount,'$')})`:''}`).join('<br/>'); 
        case DrilldownType.BUDGET:
            return (data as BudgetDrilldownDTO).map((d)=> `<span style="font-weight:bold">${d.accountName} - </span> ${displayValue(d?.actual,'$')} (Planned ${displayValue(d?.planned,'$')})`).join('<br/>');        
        case DrilldownType.COLLECTION:
            return (data as CollectionDrilldownDTO) ? 'Accumulative percentages by weeks:<br/>'+(data as CollectionDrilldownDTO)?.map((w)=> `<span style="font-weight:bold">${w.date ? `${(new Date(w.date)).toLocaleDateString()}: ` : ''} </span> ${w.accumulativePrecentage}%`).join('<br/>') : 'What else would you like to know about this collection?';        
        case DrilldownType["TRAFFIC_APPLICANT"]:
            return `Top 5 units in terms of traction (most traffic & most applications):<br/> ${(data as TrafficApplicantsDrilldownDTO).topTracted.map((d)=> `<span style="font-weight:bold">${d.unitNumber} - </span> Had ${d.visits} visitors over the last week, along with ${d.applications} applicants.`).join('<br/>')}<br/>`+
            `Lowest 5 units:<br/> ${(data as TrafficApplicantsDrilldownDTO).leastTracted.map((d)=> `<span style="font-weight:bold">${d.unitNumber} - </span> Had ${d.visits} visitors over the last week, along with ${d.applications} applicants.`).join('<br/>')}`;        
        case DrilldownType.RENEWALS:
            const renewals = (data as RenewalsDrilldownDTO).map((d)=> `<span style="font-weight:bold">${d.count} units ${d.unitType ? `${d.percentage ? `(${d.percentage}%)`:''} of unit type ${d.unitType}`:''} - </span> ${d.tenantChange ? `${d.tenantChange}`:''}`).join('<br/>');     
            return renewals;
        case DrilldownType.LOSS_TO_LEASE:
            return `<b><u>Top 5 Units:</u></b><br/><br/>${((data as LossToLeaseDrilldownDTO).slice(0,4)).map((d)=> `<span style="font-weight:bold">${d.unitNumber}</span><br/>Rent: ${displayValue(d?.amount,'$')}${d.marketRent ? `<br/>Calculated market value: ${displayValue(d?.marketRent,'$')}`:''} ${d.loss ? `<br/>Loss value: ${displayValue(d?.loss,'$')}`:''} ${d.lossRatio ? ` (${d.lossRatio}%)`:''} <br/>`).join('<br/>')}<br/><br/>       
            <b><u>Bottom 5 Units:</u></b><br/><br/>${((data as LossToLeaseDrilldownDTO).slice(-4)).map((d)=> `<span style="font-weight:bold">${d.unitNumber}</span><br/>Rent: ${displayValue(d?.amount,'$')}${d.marketRent ? `<br/>Calculated market value: ${displayValue(d?.marketRent,'$')}`:''} ${d.loss ? `<br/>Loss value: ${displayValue(d?.loss,'$')}`:''} ${d.lossRatio ? ` (${d.lossRatio}%)`:''} <br/>`).join('<br/>')}`
        default: return "Ask me further, what would you like to know?";
    }
}