import { mockUserData, mockDailyInsights, mockProperties, mockPropertiesOverall, searchHistory, loriResponseMock, mockDecisionTree, ReportLinkMock, mockSavedReports, _MockTrendJson, MockTrends, mockUnits, mockMetrics, MockCustomTrend, DrilldownMockDTO, RoomsNumMock, AddressListMock, HistoricalTrendLineMock, RoomsMock, PortfolioHistoricalTrendLineMock, mockNearbyComps, PromptsMock, GraphSuggestionsMock, GraphsMocks } from "../mock-data/mock-data"
import axios from 'axios';
import { MessageDTO, CustomTrendDTO, FeedbackDTO, GenerateReportDTO, PropertiesDTO, PropertiesOverallDTO, ROLE, RegisterInsightDTO, SavedReportsDTO, LoriResponseDTO, DrilldownResponseType, DrilldownResponseDTO, TrendGraphDTO, GraphRequestDTO, MetricGraph, GranularityType, RentalDataDTO } from "../models/models";
import { convertCSVToExcel, sleep } from "../utils/helpers";
import { DrilldownType } from "@components/Drilldown";
import { getDemoGraph } from "../mock-data/mock-graphs";
import { drilldownText, parseDrilldownText } from "../utils/drilldownParser";
import Properties from "@components/Properties";
import axiosRetry from 'axios-retry';


let headers: {authorization: string|null} = {
    authorization: null
};

const baseUri = import.meta.env.VITE_API_BASE;

axiosRetry(axios, { 
    retries: 3, // Number of retries
    retryDelay: (retryCount) => {
      return retryCount * 1000; // Time between retries (in milliseconds)
    },
    retryCondition: (error) => {
      // Retry on network errors or 5xx errors
      return error?.response?.status && error?.response?.status >= 500 || !error.response;
    },
  });

export const setAuthToken = (token:string|null) => {
  if (token) {
      headers = {
        authorization: token && `Bearer ${token}`
    };
  } else {
      // Delete the auth header
      headers = {
        authorization: null
    };
  }
};

export const login = async () => {
    axios.get(`${baseUri}/user/login`, { withCredentials: true, headers}).then((response) => {
        console.log(response);
    })
        .catch((err) => console.log(err));
}

export const auth = async () => {
    axios.get(`${baseUri}/user/auth`,{headers}).then((response) => {
    console.log(response);
    })
        .catch((err) => console.log(err));
}

export const getUserData = async () => {
    // return mockUserData;
    return axios.get(`${baseUri}/users`,{headers}).then((response) => {
        console.log(response.data);
        return response.data;
    })
        .catch((err) => console.log(err));
}

export const getPropertiesOverall = async (period?: string):Promise<PropertiesOverallDTO> => {
    // return mockPropertiesOverall;
    return axios.get(`${baseUri}/properties/summary`, 
    {
        headers, 
        params: { period: period ? (new Date(period)).toJSON() : (new Date()).toJSON() } 
    }).then((response) => {
        console.log(response.data);
        return response.data;
    }).catch((err) => console.log(err));
}

export const getProperties = async (period?: string):Promise<PropertiesDTO> => {
    // return mockProperties;
    return axios.get(`${baseUri}/properties`, 
    {
        headers,         
        params: { period: period ? (new Date(period)).toJSON() : (new Date()).toJSON() } 
    }).then((response) => {
        console.log(response.data);
        return response.data;
    })
        .catch((err) => console.log(err));
}

export const getUnits = async ():Promise<PropertiesDTO> => {
    return mockUnits;
    return axios.get(`${baseUri}/units`, {headers}).then((response) => {
        console.log(response.data);
        return response.data;
    })
        .catch((err) => console.log(err));
}

export const getMetrics = async ():Promise<MetricGraph[]> => {
    // return mockMetrics;
    return axios.get(`${baseUri}/graphs/metrics`, {headers}).then((response) => {
        console.log(response.data);
        return response.data;
    })
        .catch((err) => console.log(err));
}

export const getInsights = async () => {
    // return mockDailyInsights;
    return axios.get(`${baseUri}/insights`, {headers}).then((response) => {
        console.log(response.data);
        return response.data;
    })
        .catch((err) => console.log(err));
}

export const setInsightIsDone = async (insightId:string) => {
    // return mockDailyInsights;
    return axios.put(`${baseUri}/insights/${insightId}/done`, {}, {headers}).then((response) => {
        console.log(response.data);
        return response.data;
    })
        .catch((err) => console.log(err));
}

export const registerInsight = async (params:RegisterInsightDTO) => {
    return axios.post(`${baseUri}/insights`, {...params}, {headers}).then((response) => {
        console.log(response.data.data);
        return response.data.data;
    })
        .catch((err) => console.log(err));
}

export const getDecisionTree = async () => {
    return mockDecisionTree;
}

export const getSearchHistory = async () => {
    // return axios.get(`${baseUri}/lori/suggestions`, {headers}).then((response) => {
    //     console.log(response.data);
    //     return response.data;
    //   })
    //   .catch((err) => console.log(err));

    return searchHistory;
}

export const sendFeedback = async (params:FeedbackDTO) => {
    // return;
    return axios.post(`${baseUri}/feedback`, {...params}, {headers}).catch((err) => console.log(err));
}

export const askLori = async (conversationID: number, prompt: string):Promise<{conversationID: number,messages: string[]}> => {
    // return {
    //     conversationID: 4,
    //     messages: ["Hi, I'm Lori", "How are you today?"]
    // };
    return axios.post(`${baseUri}/lori`, {conversationID, prompt}, {headers}).then((response) => {
        // let resp:{conversationID: number,messages: string[]} = {conversationID: response.data.conversationID, messages: []};
        // let lines = response.data.messages.map((m:string) => m.split('\n')); 
        // lines.forEach((l:string) => resp.messages.push(l)); 
        // return resp;
        console.log(response.data);
        return response.data;
    })
    .catch(() => {
        return axios.post(`${baseUri}/lori`, {conversationID, prompt:`${prompt} short answer please`}, {headers}).then((response) => {
            console.log(response.data);
            return response.data;
        }).catch((err) => {console.log(err)});
    });

    // await sleep(500);
    // const q: ChatMessageDTO = {role: ROLE.USER, content: prompt, time: Date.now().toLocaleString()};
    // const a: ChatMessageDTO = {role: ROLE.ASSISTANT, content: "message bla bla bla", time: Date.now().toLocaleString()};
    // return [...messages, q, a];
}

export const uploadWithLori = async (conversationID: number, file: File):Promise<LoriResponseDTO> => {
  const formData = new FormData();
  formData.append("file", file);
  return {
      conversationID: 4,
      messages: ["All set, what would you like to ask?"]
  };
  return axios.post(`${baseUri}/lori/upload`, {conversationID, formData}, {headers}).then((response) => {
      console.log(response.data);
      return response.data;
})
      .catch((err) => console.log(err));
}

export const generateReport = async (params:GenerateReportDTO):Promise<string> => {
    return ReportLinkMock;

    // return axios.post(`${baseUri}/reports`, {...params}, {headers}).then((response) => {
    //     console.log(response.data);
    //     return response.data;
    //   })
    //   .catch((err) => console.log(err));
}

export const getSavedReports = async ():Promise<SavedReportsDTO> => {
    // return mockSavedReports;
    // return {LP:[], INTERNAL:[]};//mockSavedReports;

    return axios.get(`${baseUri}/reports`, {headers}).then((response) => {
        console.log(response.data);
        return response.data;
      })
      .catch((err) => console.log(err));
}

export const getTrends = async ():Promise<TrendGraphDTO[]> => {
    return [];//MockTrends;
    return axios.get(`${baseUri}/graphs`, {headers}).then((response) => {
        console.log(response.data);
        return response.data;
    })
    .catch((err) => {
        console.log(err);
    });

}

export const generateTrendGragh = async ({track, date_range, granularity, graph_name, chart_type, properties, per_property}: GraphRequestDTO):Promise<TrendGraphDTO> => {
    // return dataPoints || _MockTrendJson;

    return axios.post(`${baseUri}/graphs/metrics`, {track, date_range, graph_name, granularity, chart_type, properties, per_property}, {headers}).then((response) => {
        console.log(response.data);
        if(response.status === 204){
            return;
        }
        return response.data;
    })
    .catch((err) => {
        console.log(err);
        throw("Graph can't be generated. Please adjust the selected parameters to generate a meaningful graph. ")
    });
}

export const generateTrendGraphByPrompt = async (prompt:string, track?: boolean):Promise<TrendGraphDTO[]> => {
  // return PromptsMock;
    return axios.post(`${baseUri}/graphs/prompts`, {graph_name: prompt, track}, {headers}).then((response) => {
        console.log(response.data);
        if(response.status === 204){
            return;
        }
        return response.data;
    })
    .catch((err) => {
        console.log(err);
    });
}

export const deleteGraph = async (id: string):Promise<TrendGraphDTO[]> => {
    return axios.delete(`${baseUri}/graphs/${id}`, {headers}).then((response) => {
        console.log(response.data);
        return response.data;
    })
    .catch((err) => {
        console.log(err);
    });
}

export const getGraphSuggestions = async ():Promise<{text: string; graph_name: string}[]> => {
  // return GraphSuggestionsMock;
    return axios.get(`${baseUri}/graphs/prompts`, {headers}).then((response) => {
        console.log(response.data);
        return response.data;
    })
    .catch((err) => {
        console.log(err);
    });
}

export const getGraphs = async ():Promise<{conversationID: number,messages: string[]}> => {
  // return GraphsMocks;  
  return axios.get(`${baseUri}/graphs`, {headers}).then((response) => {
        console.log(response.data);
        return response.data;
    })
    .catch((err) => {
        console.log(err);
    });
}


export const generateCustomTrend = async (props:CustomTrendDTO):Promise<TrendGraphDTO> => {
    return MockCustomTrend(props);
}


export const getDrilldown = async (id:string, type?: DrilldownType):Promise<string|void> => {
    //[DrilldownType is used only for demo purposes]
    // return DrilldownMockDTO(type);

    if(!id) return;
    return axios.get(`${baseUri}/drilldowns/${id}`, {headers}).then((response) => {
        console.log(response.data);
        return parseDrilldownText(response.data);
      })
      .catch((err) => {
        console.error(err?.data||err);
        return drilldownText();
      });
}

export const getDrilldownExport = async (id:string, name:string, date: string):Promise<string|void> => {
    //[DrilldownType is used only for demo purposes]
    // return convertCSVToExcel(`Username; Identifier;First name;Last name
    // booker12;9012;Rachel;Booker
    // grey07;2070;Laura;Grey
    // johnson81;4081;Craig;Johnson
    // jenkins46;9346;Mary;Jenkins
    // smith79;5079;Jamie;Smith`);

    if(!id) return;
    return axios.get(`${baseUri}/drilldowns/export/${id}`, {headers}).then((response) => {
        return convertCSVToExcel(response.data, name, date);
      })
      .catch((err) => {
        console.error(err?.data||err);
      });
}

export const updateGraphParam = async ({id, granularity, properties }:
    {id: string, granularity?:GranularityType, properties?: string[], title?: string,date_range?: string[]}) => {
     if(!id) return;
    return axios.patch(`${baseUri}/graphs/${id}`, {granularity, properties}, {headers}).then((response) => {
        return response.data;
      })
      .catch((err) => {
        console.error(err?.data||err);
      });
}

export const getCompsProperties = async () => {
    return axios.get(`${baseUri}/comps/properties`, {headers}).then((response) => {
        console.log(response.data);
        return response.data;
      })
      .catch((err) => {
        console.log(err);
    });
}

export const getRooms = async (address: string) => {
    // return RoomsMock;
    return axios.get(`${baseUri}/comps/rooms`, {params: {address} ,headers}).then((response) => {
        console.log(response.data);
        return response.data;
      })
      .catch((err) => {
        console.log(err);
    });
}

export const getAvgRent = async({address, bedrooms}:{address:string, bedrooms: number|null}) =>{
    // return bedrooms ? 3874:3889;
    return axios.get(`${baseUri}/comps/avg-rent`, {params: {address, bedrooms}, headers}).then((response) => {
        return response.data;
      })
      .catch((err) => {
        console.error(err?.data||err);
      });
}

export const getPortfolioAvgRent = async({address, bedrooms}:{address:string, bedrooms: number|null}) =>{
    return bedrooms ? 2980:2900;
    return axios.get(`${baseUri}/comps/avgRent`, {params: {address, bedrooms}, headers}).then((response) => {
        return response.data;
      })
      .catch((err) => {
        console.error(err?.data||err);
      });
}

export const getRentalData = async({address, bedrooms}:{address:string, bedrooms: number|null}):Promise<RentalDataDTO[]> =>{
    // return mockProperties.slice(3,8).map(m=> { return{
    //     address: m.address ?? m.name,
    //     rent: 4500,
    //     lat: 51.505,
    //     lng: -0.09,
    //   }});
    const r =  await getNearbyComps({address, bedrooms});
    let result = [...r.nearbyComps];
    //add the searched address as the first element in the list:
    result.unshift({address: r.searchedAddress.address, latitude: r.searchedAddress.latitude,longitude: r.searchedAddress.longitude });
    return result;
    // return comps.nearbyComps.map(c=>({address,rent:c.price, lat: c.latitude, lng: c.longitude}));
}

export const getNearbyComps = async({address, bedrooms}:{address:string, bedrooms: number|null}) =>{
    // return mockNearbyComps;
    return axios.get(`${baseUri}/comps/nearby`, {params: {address, bedrooms}, headers}).then((response) => {
        return response.data;
      })
      .catch((err) => {
        console.error(err?.data||err);
      });
}

export const getHistoricalTrendLine = async({address, bedrooms}:{address:string, bedrooms: number|null}):Promise<TrendGraphDTO> =>{
    return HistoricalTrendLineMock;
}

export const getPortfolioHistoricalTrendLine = async({address, bedrooms}:{address:string, bedrooms: number|null}):Promise<TrendGraphDTO> =>{
    return PortfolioHistoricalTrendLineMock;
}

export const getAvgRentByBedroomType = async({address, bedrooms}:{address:string, bedrooms: number|null}) =>{
    return axios.get(`${baseUri}/comps/avg-rent-by-bedrooms`, {params: {address, bedrooms}, headers, timeout: 900000}).then((response) => {
        return response.data;
      })
      .catch((err) => {
       throw(err?.data||err);
      });
}

export const getRentDistribution = async({address, bedrooms}:{address:string, bedrooms: number|null}) =>{
    return axios.get(`${baseUri}/comps/rent-distribution`, {params: {address, bedrooms}, headers, timeout: 900000}).then((response) => {
        return response.data;
      })
      .catch((error) => {
        if (error.code === 'ECONNABORTED') {
            console.error('Timeout occurred:', error.message);
        } else if (error.response) {
            console.error('Server responded with non-2xx status:', error.response.status);
        } else {
            console.error('Error setting up request:', error.message);
        }
      });
}

export const compsExport = async({address, bedrooms}:{address:string, bedrooms: number|null}) =>{
    return axios.get(`${baseUri}/comps/export`, {params: {address, bedrooms}, headers}).then((response) => {
        console.log(response.data)
        window.open(response.data.url);
        return response.data;
      })
      .catch((err) => {
        throw (err?.data||err);
      });
}

// export const getPublicRecordData = async({address, bedrooms}:{address:string, bedrooms: number|null}) =>{
//     return PublicRecordDataMock;
// }