import { LargeText, SmallText, Subtext } from "../styles/Typography";
import { ArrowBubbleNegative, ArrowBubblePositive, DangerIcon } from "./Icons";
import { displayValue } from "../utils/helpers";
import { Bubble } from "./Bubble";

enum RISK_STATUS {
  DANGER = 2,
  WARNING = 1,
  GOOD = 0,
}
type Metric = {
  title: string;
  value: number | null;
  prefix?: string;
  suffix?: string;
};

type LineMetricProps = {
  metric1?: Metric;
  metric2?: Metric;
  trend?: Metric;
  reversePercentageLine?: boolean;
  customRiskStatus?: boolean;
  small?: boolean;
};

const getRiskStatus = (value1: number, value2: number) => {
  if (!value2) {
    if (value1 < 0) return RISK_STATUS.DANGER;
    return RISK_STATUS.GOOD;
  }
  if (value2 < value1) return RISK_STATUS.DANGER;
  return RISK_STATUS.GOOD;
};

const getCustomRiskStatus = (isPositiveRiskValue: boolean) => {
  return isPositiveRiskValue ? RISK_STATUS.GOOD : RISK_STATUS.DANGER;
};

const getColor = (risk: RISK_STATUS) => {
  switch (risk) {
    case RISK_STATUS.DANGER:
      return "#F4312F";
    case RISK_STATUS.WARNING:
      return "#FBBC04";
    default:
      return "#23B939";
  }
};

export const TrendMetric = ({
  trend,
  reverseRiskColor,
}: {
  trend: number;
  reverseRiskColor?: boolean;
}) =>
  trend !== undefined && trend !== null ? (
    <div
      className={`flex items-center gap-1 text-xs ${
        (reverseRiskColor && trend >= 0) || (!reverseRiskColor && trend < 0)
          ? "!text-red"
          : "!text-green-400"
      }`}
    >
      <Bubble
        className={
          (reverseRiskColor && trend >= 0) || (!reverseRiskColor && trend < 0)
            ? "bg-red/10"
            : "bg-green-400/10"
        }
      >
        {(reverseRiskColor && trend >= 0) ||
        (!reverseRiskColor && trend < 0) ? (
          <ArrowBubbleNegative />
        ) : (
          <ArrowBubblePositive />
        )}
      </Bubble>
      {displayValue(trend, undefined, "%")}
    </div>
  ) : (
    ""
  );

export const LineMetric = ({
  metric1,
  metric2,
  trend,
  reversePercentageLine,
  customRiskStatus,
  small,
}: LineMetricProps) => {
  const status = customRiskStatus
    ? getCustomRiskStatus(customRiskStatus)
    : getRiskStatus(
        metric1?.value != null
          ? reversePercentageLine
            ? 100 - metric1.value
            : metric1.value
          : 0,
        metric2?.value != null
          ? reversePercentageLine
            ? 100 - metric2.value
            : metric2.value
          : 0
      );

  const color = getColor(status);

  const getLineWidth = () => {
    let result = 0;
    if (metric2?.value == null && trend?.value == null) {
      result = 100;
    }

    if (metric2?.value == null) {
      if (metric1?.value) {
        if (!!reversePercentageLine) {
          result = 100 - metric1.value;
        } else {
          if (metric1?.value > 100) {
            result = 100;
          }
        }
        result = metric1?.value;
      } else {
        if (metric1?.value === null) result = 0;
      }
    }

    if (metric1?.value && metric2?.value) {
      result =
        Math.min(
          1,
          reversePercentageLine
            ? 1 - metric1.value / (metric2.value || 1)
            : metric1.value / (metric2.value || 1)
        ) * 100;
    }

    return result >= 100
      ? "100%"
      : result <= 0
      ? "0%"
      : result.toFixed(2) + "%";
  };

  return (
    <div className="relative flex justify-between w-full">
      <div>
        <Subtext>{metric1?.title}</Subtext>
        {small ? (
          <SmallText
            style={{
              color,
            }}
            className="flex items-center gap-1 text-[10px] !mt-0 !mb-1"
          >
            {status === RISK_STATUS.DANGER ? <DangerIcon /> : ""}
            {displayValue(
              metric1?.value == null ? null : metric1?.value,
              metric1?.prefix,
              metric1?.suffix
            )}
          </SmallText>
        ) : (
          <LargeText
            style={{
              color,
            }}
            className="flex items-center gap-1"
          >
            {status === RISK_STATUS.DANGER ? <DangerIcon /> : ""}
            {displayValue(
              metric1?.value == null ? null : metric1?.value,
              metric1?.prefix,
              metric1?.suffix
            )}
          </LargeText>
        )}
      </div>
      <div>
        <Subtext>{metric2?.title}</Subtext>
        {trend?.value != null ? (
          <TrendMetric
            reverseRiskColor={reversePercentageLine}
            trend={trend.value}
          />
        ) : small ? (
          <SmallText className="text-secondary-gray-400 text-[10px] !mt-0 !mb-1 w-full text-right">
            {displayValue(
              metric2?.value == null ? null : metric2?.value,
              metric2?.prefix,
              metric2?.suffix
            )}
          </SmallText>
        ) : (
          <LargeText className="text-secondary-gray-400">
            {displayValue(
              metric2?.value == null ? null : metric2?.value,
              metric2?.prefix,
              metric2?.suffix
            )}
          </LargeText>
        )}
      </div>
      <div
        className="absolute h-fit bottom-0 w-full"
        style={
          {
            // width: metric2?.title === "Planned" ? "100%" : "calc(100% - 80px)",
          }
        }
      >
        <div
          style={{
            borderColor: color,
            opacity: 0.3,
          }}
          className={`absolute bottom-0 w-full border-b-[3px]`}
        ></div>
        <div
          style={{
            width: getLineWidth(),
            borderColor: color,
          }}
          className={`absolute bottom-0 w-1/2 border-b-[3px]`}
        ></div>
      </div>
    </div>
  );
};
